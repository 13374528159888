import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import _ from 'lodash'
import styled from 'styled-components'

import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import Container from '@objects/container'
import Button from '@objects/button'
import Filter from './filter'
import SinglePdf from '@objects/singlePdf'
import Icon from '@objects/icon'

const FilterButton = styled.button`
  padding: ${({ theme }) =>
    `2px ${theme.spacing['3']} 0 ${theme.spacing['4']}`};
  border: 2px solid ${({ theme }) => theme.colors.blue.default};
  margin: ${({ theme }) => `${theme.spacing['3']} ${theme.spacing['3']} 0 0`};
  border-radius: ${({ theme }) => theme.spacing['3']};
  line-height: 1;

  .delete {
    margin-left: ${({ theme }) => theme.spacing['2']};
    padding-bottom: 2px;
    color: ${({ theme }) => theme.colors.red.default};
  }
`

function PDFList({
  className,
  items,
  categoryfilter,
  showmore,
  initialshow,
  loadpershowmore,
  showmoretext,
  labelId,
}) {
  const location = useLocation()
  const intl = useIntl()
  const fullItemlist = items.map((item) => {
    item.filterId = item.tag?.toLowerCase().replace(/ /g, '')
    return item
  })
  const categoryfilterWithId =
    categoryfilter?.map((cat) => {
      cat.filterId = cat.title?.toLowerCase().replace(/ /g, '') || ''
      return cat
    }) || []
  const filterAllLabel = intl.formatMessage({ id: 'download.all' })
  const filterAllId = filterAllLabel.toLowerCase().replace(/ /g, '')

  const [currentFilter, setCurrentFilter] = useState([filterAllId])
  const [filteredItemList, setFilteredItemList] = useState(fullItemlist)
  const [loadnumber, setLoadnumber] = useState(initialshow || 500)
  const [hideloadmore, setHideloadmore] = useState(false)

  useEffect(() => {
    if (!!categoryfilter?.length) {
      setFilteredItemList(
        items.filter(
          (t) =>
            !categoryfilter ||
            currentFilter.includes(filterAllId) ||
            currentFilter.includes(t.filterId)
        )
      )

      navigate(
        `${location.pathname}#${queryString.stringify({
          articlefilter: currentFilter.join(','),
        })}`,
        {
          replace: true,
          state: {
            preventScroll: true,
          },
        }
      )
    }
  }, [currentFilter])

  useEffect(() => {
    const filterParam = queryString.parse(location.hash).articlefilter
    const newFilter = filterParam ? filterParam.split(',') : []
    const validFilter = newFilter.filter(
      (filter) =>
        categoryfilterWithId?.find((cat) => cat.filterId === filter) ||
        filter === filterAllId
    )

    if (validFilter.length && _.xor(newFilter, currentFilter).length) {
      setCurrentFilter(validFilter)
    }
  }, [])

  function filterButtonClick(id) {
    if (!(id === filterAllId && currentFilter.includes(id))) {
      setCurrentFilter((prevState, props) => {
        let newState = [...prevState]
        if (newState.includes(id)) {
          newState = _.pull(newState, id)
        } else {
          if (id === filterAllId) {
            newState = [filterAllId]
          } else {
            newState.push(id)
            newState = _.pull(newState, filterAllId)
          }
        }
        return newState.length ? newState : [filterAllId]
      })
    }
  }

  function renderList() {
    let result = []

    let rowIndex = 0
    let rows = 0

    for (let i = 0; i < filteredItemList.length; i++) {
      const pdfItem = filteredItemList[i]
      if (rowIndex >= 1) {
        rows++
        rowIndex = 0
      }

      // break if showmore
      if (showmore && rows === loadnumber) {
        break
      }

      rowIndex += 2
      result.push(
        <SinglePdf
          key={pdfItem.title + i}
          title={pdfItem.title}
          description={pdfItem.description}
          updatedAt={pdfItem.updatedAt}
          fileurl={pdfItem.fileurl}
          size={pdfItem.size}
        />
      )
    }

    if (showmore) {
      if (!hideloadmore && result.length >= filteredItemList.length) {
        setHideloadmore(true)
      } else if (hideloadmore && result.length < filteredItemList.length) {
        setHideloadmore(false)
      }
    }

    return result
  }

  function renderSelectedFilter() {
    return currentFilter.map((filter) => (
      <FilterButton
        key={`delete-${filter}`}
        onClick={() => filterButtonClick(filter)}
      >
        {categoryfilterWithId?.find((cat) => cat.filterId === filter)?.title}
        <Icon name="Close" className={'delete text-xl'} />
      </FilterButton>
    ))
  }

  function onLoadMoreClick() {
    setLoadnumber(loadnumber + loadpershowmore)
  }

  return (
    <>
      {categoryfilter && (
        <div className="mb-8 md:mb-10">
          <div className="bg-gray-light-300">
            <Container className="py-8" nopadding width="md">
              <Filter
                filteritems={categoryfilterWithId}
                currentFilter={currentFilter}
                filterButtonClick={filterButtonClick}
                filterAll={{
                  label: `${filterAllLabel} (${items.length})`,
                  id: filterAllId,
                }}
              />
            </Container>
          </div>
          {!currentFilter.includes(filterAllId) && (
            <Container className="mt-2 -mb-8" nopadding width="md">
              {renderSelectedFilter()}
            </Container>
          )}
        </div>
      )}
      <Container className="mb-11 md:mb-13" nopadding width="md">
        <div id="pdflist" aria-labelledby={labelId} className={clsx(className)}>
          {renderList()}
          {showmore && !hideloadmore && (
            <div className="text-center mt-8 sm:mt-9">
              <Button showmore onClick={onLoadMoreClick}>
                {showmoretext}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

PDFList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      fileurl: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      tag: PropTypes.string,
    })
  ),
  showmore: PropTypes.bool,
  initialshow: PropTypes.number,
  loadpershowmore: PropTypes.number,
  showmoretext: PropTypes.string,
  categoryfilter: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  labelId: PropTypes.string,
}

export default PDFList
