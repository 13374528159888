import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl, FormattedMessage } from 'react-intl'

import styled from 'styled-components'

import Dropdown from '@objects/dropdown'
import Icon from '@objects/icon'

const FilterItem = styled.button`
  display: block;
  padding: ${({ theme }) => `${theme.spacing['1']} 0`};
  border: 0;
  background: none;

  .checkbox {
    border: 1px solid #000;
    margin-right: ${({ theme }) => theme.spacing['2']};
    background: #fff;
    color: #fff;
  }
  &.active .checkbox {
    color: ${({ theme }) => theme.colors.red.default};
  }
`

function Filter({
  className,
  filteritems,
  currentFilter,
  filterButtonClick,
  filterAll,
}) {
  const intl = useIntl()

  function renderButton(id, name) {
    return (
      <FilterItem
        key={id}
        className={clsx('item', {
          active: currentFilter.includes(id),
        })}
        onClick={() => filterButtonClick(id)}
        role="option"
        aria-selected={currentFilter.includes(id)}
      >
        <Icon name="Checked" className={'checkbox text-xl'} />
        {name}
      </FilterItem>
    )
  }

  function renderFilterButtons() {
    return [renderButton(filterAll.id, filterAll.label)].concat(
      filteritems.map((item) =>
        renderButton(item.filterId, `${item.title} (${item.files?.length})`)
      )
    )
  }

  return (
    <div className="md:w-1/2 lg:w-4/10">
      <p className="text-lg mb-3 font-medium">
        <FormattedMessage id="label.filter" />
      </p>
      <Dropdown
        label={intl.formatMessage({ id: 'download.topics' })}
        multiselect="pdflist"
      >
        {renderFilterButtons()}
      </Dropdown>
    </div>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  filteritems: PropTypes.array.isRequired,
  currentFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterButtonClick: PropTypes.func.isRequired,
  filterAll: PropTypes.object,
}

export default Filter
