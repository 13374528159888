import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { useIntl, FormattedMessage } from 'react-intl'
import useDateFns from '@hooks/useDateFns'

import Container from '@objects/container'
import Headline from '@objects/headline'
import PDFList from '@components/pdfList'
import TracklineBackground from '@objects/tracklineBackground'

export const frontmatter = {
  breadcrumbs: [
    { label: 'Downloads & Presse', link: '/downloads-und-presse/' },
  ],
}

function PlanPdfPage({ data }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()

  const PdfCatList = get(data, 'contentfulPdfCategoryList')

  const PdfCats = PdfCatList?.pdfCategories

  const mappedCats = PdfCats.map((cat) => ({
    title: cat.title,
    files: cat.pdFs?.map((file) => ({
      tag: cat.title,
      title: file.title,
      updatedAt: file.updatedAt,
      description: file.description?.childMarkdownRemark?.html,
      fileurl: file?.file?.file?.url,
      size: (file?.file?.file?.details?.size / (1024 * 1024)).toFixed(2),
    })),
  }))

  function getAllFiles() {
    let files = []
    mappedCats.forEach((cat) => {
      if (!cat.files) return
      files.push(...cat.files)
    })
    return files.sort((a, b) => differenceInDays(b.updatedAt, a.updatedAt))
  }

  return (
    <>
      <Container nopadding className="pt-6 md:pt-9" role="region">
        <TracklineBackground track={1} top={'500px'} />
        <TracklineBackground track={5} right top={'600px'} />

        <Headline id="downloads" level={1} ariaLabel={PdfCatList.title}>
          {PdfCatList.title}
        </Headline>
        <p className="mb-8 md:mb-10 font-normal text-black-650">
          (<span className="mr-0.5">{getAllFiles().length}</span>
          <span>
            <FormattedMessage id="downloads.elements" />
          </span>
          )
        </p>
      </Container>
      <PDFList
        showmore
        initialshow={5}
        loadpershowmore={5}
        showmoretext={intl.formatMessage({ id: 'button.loadmore' })}
        items={getAllFiles()}
        categoryfilter={mappedCats}
        labelId="downloads"
      />
    </>
  )
}

PlanPdfPage.propTypes = {
  data: PropTypes.any,
}

export default PlanPdfPage

export const pageQuery = graphql`
  query PlanPdfPageQuery($locale: String!) {
    contentfulPdfCategoryList(
      identifier: { eq: "berichte-und-planungsunterlagen" }
      node_locale: { eq: $locale }
    ) {
      title
      pdfCategories {
        title
        pdFs {
          title
          file {
            file {
              url
              details {
                size
              }
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          identifier
          updatedAt
        }
      }
    }
  }
`
